<script setup lang="ts">
import type { SearchProductsQueryParameters } from '@yescapa-dev/ysc-api-js/legacy'
import {
  LOCALE_CODES,
} from '@yescapa-dev/ysc-components/i18n'
import type { RouteLocationRaw } from '#vue-router'
import {
  DRAWING_A_CLASS,
  DRAWING_COACHBUILT_BIKES,
  DRAWING_COMBI,
  DRAWING_CONVERTED_VAN_SURF,
  DRAWING_LOW_PROFILE_BIKES,
  DRAWING_T6_TOPUP,
} from '~/constants/pictures'

interface Props {
  bgImage?: string | null
  bgImageAlt?: string | null
  withStamp?: boolean
  withDrawing?: boolean
  extraQuery?: SearchProductsQueryParameters | null
}
const props = withDefaults(defineProps<Props>(), {
  bgImage: null,
  bgImageAlt: null,
  withStamp: false,
  withDrawing: false,
  extraQuery: null,
})

const { data } = useAsyncData('app-page-hero', () => {
  const drawing = [
    DRAWING_T6_TOPUP,
    DRAWING_A_CLASS,
    DRAWING_COACHBUILT_BIKES,
    DRAWING_LOW_PROFILE_BIKES,
    DRAWING_CONVERTED_VAN_SURF,
    DRAWING_COMBI,
  ]

  const idx = Math.floor(Math.random() * drawing.length)
  return Promise.resolve({
    selectedDrawing: drawing[idx],
  })
})

const i18n = useI18n()

const isYescapaDe = computed(() => i18n.locale.value === LOCALE_CODES.DE)

const supersizing: Ref<{
  callbacks: {
    keepNavigateAction: (() => void) | null
    redirectUrl?: RouteLocationRaw
  }
  modal: {
    show: boolean
  }
}> = ref({
  callbacks: {
    keepNavigateAction: null,
    redirectUrl: undefined,
  },
  modal: {
    show: false,
  },
})

const onOpenSupersizingModal = ({ query, redirect: redirectUrl }: { query: () => void, redirect: RouteLocationRaw }) => {
  supersizing.value.modal.show = true
  supersizing.value.callbacks = {
    keepNavigateAction: query,
    redirectUrl,
  }
}
</script>

<template>
  <div class="bg-peacock-700 relative">
    <AppSupersizingRedirectModal
      v-if="supersizing.modal.show"
      :keep-navigate-action="supersizing.callbacks.keepNavigateAction"
      :redirect-url="supersizing.callbacks.redirectUrl"
      @close="supersizing.modal.show = false"
    />

    <div
      v-if="props.withDrawing"
      class="absolute right-0 top-6 h-24 w-24 md:top-8 md:h-auto md:w-52 lg:hidden"
    >
      <YscPicture
        :path="twicpicsPath(data?.selectedDrawing, { isLibrary: true })"
        :sources="[
          { breakpoint: 'md', width: 196, height: 144, refitParameter: '@right', postRefitTransformation: [{ parameter: 'flip', value: 'x' }] },
        ]"
        :default-source="{ width: 96, height: 96, refitParameter: '@right', postRefitTransformation: [{ parameter: 'flip', value: 'x' }] }"
        fetchpriority="high"
        loading="eager"
        with-refit-transformation
        :alt="props.bgImageAlt"
      />
    </div>
    <div class="container">
      <div class="relative">
        <div class="lg:absolute lg:bottom-0 lg:right-0 lg:top-0 lg:w-[47%]">
          <YscPicture
            v-if="props.bgImage"
            :path="props.bgImage"
            :sources="[
              { breakpoint: 'xl', width: 624, height: 624, refitParameter: '(10p)' },
            ]"
            :default-source="{ width: 466, height: 480 }"
            fetchpriority="high"
            loading="eager"
            class="hidden lg:block lg:h-full lg:w-full"
            :alt="props.bgImageAlt"
          />
          <div
            v-if="props.withStamp"
            class="hidden lg:absolute lg:left-0 lg:top-8 lg:block lg:-translate-x-2/3"
          >
            <img
              src="~/assets/img/commons/stamp.svg"
              alt="-"
              fetchpriority="high"
            >
          </div>
          <div>
            <img
              v-if="isYescapaDe"
              src="~assets/img/awards/DKA2023_2.svg"
              class="hidden lg:block lg:absolute lg:w-24 lg:top-4 lg:right-4"
              alt="-"
              fetchpriority="high"
            >
          </div>
        </div>

        <div class="relative z-30 py-10 lg:py-20">
          <div class="space-y-6 pr-12 text-white md:w-[53%]">
            <p class="whitespace-pre-line text-[1.7rem] leading-tight font-semibold sm:leading-none sm:text-3xl lg:text-5xl xl:text-6xl">
              <slot name="title-big" />
            </p>
            <h1 class="text-sm md:text-base lg:text-xl xl:text-2xl">
              <slot name="title-seo" />
            </h1>
          </div>
          <div class="mt-8 rounded bg-gray-100 p-4 lg:w-3/4">
            <HoSearchBar
              :extra-query="props.extraQuery"
              with-submit-button
              @open-supersizing-modal="onOpenSupersizingModal"
            />
          </div>

          <div
            v-if="isYescapaDe"
            id="ab-insurance-variant"
          >
            <div class="relative -bottom-4 flex items-center justify-end space-x-2 text-gray-400 lg:-bottom-8 lg:pr-10 lg:w-[53%]">
              <span class="text-xs">Versicherungspartner</span>
              <img
                src="~assets/img/ab-test/allianz.svg"
                alt="-"
                fetchpriority="high"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
